

$color_1: #e6e2af;
$background_color_1: #002f2f;

html.lb-disable-scrolling {
	overflow: hidden;
	position: fixed;
	height: 100vh;
	width: 100vw;
}
.lightboxOverlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	background-color: $background_color_1;
	filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=95);
	opacity: 0.95;
	display: none;
}
.lightbox {
	position: absolute;
	left: 0;
	width: 100%;
	z-index: 10000;
	text-align: center;
	line-height: 0;
	font-weight: normal;
	.lb-image {
		display: block;
		height: auto;
		max-width: inherit;
		max-height: none;
		border-radius: 3px;
		border: 1px solid #a7a37e;
	}
	a {
		img {
			border: none;
		}
	}
}
.lb-outerContainer {
	position: relative;
	*zoom: 1;
	width: 250px;
	height: 250px;
	margin: 0 auto;
	border-radius: 4px;
	background-color: $background_color_1;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
.lb-loader {
	position: absolute;
	top: 43%;
	left: 0;
	height: 25%;
	width: 100%;
	text-align: center;
	line-height: 0;
}
.lb-cancel {
	display: block;
	width: 32px;
	height: 32px;
	margin: 0 auto;
	background: url(../../img/lightbox/loading.gif) no-repeat;
}
.lb-nav {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
	a {
		outline: none;
		background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
	}
	a.lb-prev {
		width: 34%;
		left: 0;
		float: left;
		background: url(../../img/lightbox/prev.gif) left 48% no-repeat;
		filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
		opacity: 0;
		-webkit-transition: opacity 0.3s;
		-moz-transition: opacity 0.3s;
		-o-transition: opacity 0.3s;
		transition: opacity 0.3s;
		&:hover {
			filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
			opacity: 1;
		}
	}
	a.lb-next {
		width: 64%;
		right: 0;
		float: right;
		background: url(../../img/lightbox/next.gif) right 48% no-repeat;
		filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
		opacity: 0;
		-webkit-transition: opacity 0.3s;
		-moz-transition: opacity 0.3s;
		-o-transition: opacity 0.3s;
		transition: opacity 0.3s;
		&:hover {
			filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
			opacity: 1;
		}
	}
}
.lb-container {
	>.nav {
		left: 0;
	}
}
.lb-prev {
	height: 100%;
	cursor: pointer;
	display: block;
}
.lb-next {
	height: 100%;
	cursor: pointer;
	display: block;
}
.lb-dataContainer {
	margin: 0 auto;
	padding-top: 5px;
	*zoom: 1;
	width: 100%;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
.lb-data {
	padding: 0 4px;
	color: $color_1;
	.lb-details {
		width: 85%;
		float: left;
		text-align: left;
		line-height: 1.1em;
	}
	.lb-caption {
		font-size: 13px;
		font-weight: bold;
		line-height: 1em;
		a {
			color: $color_1;
		}
	}
	.lb-number {
		display: block;
		clear: left;
		padding-bottom: 1em;
		font-size: 12px;
		color: $color_1;
	}
	.lb-close {
		display: block;
		float: right;
		width: 30px;
		height: 30px;
		background: url(../../img/lightbox/close.png) top right no-repeat;
		text-align: right;
		outline: none;
		filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
		opacity: 0.7;
		-webkit-transition: opacity 0.1s;
		-moz-transition: opacity 0.1s;
		-o-transition: opacity 0.1s;
		transition: opacity 0.1s;
		&:hover {
			cursor: pointer;
			filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
			opacity: 1;
		}
	}
}
